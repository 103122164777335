// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBi7-Ito3CSMP6_dlizt7lE4Uf6hFva2CM",
  authDomain: "gasbillcs.firebaseapp.com",
  databaseURL: "https://gasbillcs-default-rtdb.firebaseio.com",
  projectId: "gasbillcs",
  storageBucket: "gasbillcs.appspot.com",
  messagingSenderId: "883357812861",
  appId: "1:883357812861:web:4f24033c530f2289c54914",
  measurementId: "G-QTNM86CPB2"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };